@use 'mixins' as *;

.p {
  color: var(--color-hg-black);
  font-size: var(--font-size, var(--font-size-body16));
  line-height: var(--line-height-body16);
  font-weight: var(--font-weight-regular);
  &.label {
    font-size: var(--font-size, var(--font-size-label));
    line-height: var(--line-height-label);
    font-weight: var(--font-weight-medium);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &.small {
    font-size: var(--font-size, var(--font-size-body14));
    line-height: var(--line-height-body14);
    font-weight: var(--font-weight-regular);
  }
}
.white {
  color: var(--color-hg-white);
}
.center {
  text-align: center;
}
.h1 {
  font-size: var(--font-size-header1-mobile);
  line-height: var(--line-height-header1-mobile);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.01em;
  @include breakpoint-min(small) {
    font-size: var(--font-size-header1);
    line-height: var(--line-height-header1);
  }

  &.bold {
    font-weight: var(--font-weight-bold);
  }
  &.medium {
    font-weight: var(--font-weight-medium);
  }
}
.h2 {
  font-size: var(--font-size-header2);
  line-height: var(--line-height-header2);
  font-weight: var(--font-weight-regular);
  &.bold {
    font-weight: var(--font-weight-bold);
  }
  &.medium {
    font-size: var(--font-size-header2-medium);
    line-height: var(--line-height-header2-medium);
    font-weight: var(--font-weight-medium);
  }
}
.h3 {
  font-size: var(--font-size-header3);
  line-height: var(--line-height-header3);
  font-weight: var(--font-weight-regular);
}
.h4 {
  font-size: var(--font-size-header4);
  line-height: var(--line-height-header4);
  font-weight: var(--font-weight-regular);
  &.medium {
    font-weight: var(--font-weight-medium);
  }
}
