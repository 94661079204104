/* Main */
:root {
  --header-height: 56px;
  --map-offset: 0;
  --map-width: 100%;
  --map-height: 500px;
  --map-height-desktop: 600px;
  --content-width-medium: 51.875rem;
}

// Colors
:root {
  --color-hg-black: #212126;
  --color-hg-blue: #2b79ab;
  --color-hg-gray1: #313138;
  --color-hg-gray2: #cccccc;
  --color-hg-gray3: #f7f7f7;
  --color-hg-gray4: #e6e6e6;
  --color-hg-gray5: #616161;
  --color-hg-white: #ffffff;
  --color-black: #111111;
  --color-black-hover: #2d2d2d;
  --color-boxshadow-transparent: rgba(0, 0, 0, 0.1);
  --color-gray1: #90909b;
  --color-gray2: #b3b4bc;
  --color-gray3: #dedede;
  --color-gray4: #e6e6e6;
  --color-gray5: #f8f8fc;
  --color-gray6: #f7f7f7;
  --color-hell-no: #ff1e1e;
  --color-hell-yeah: #67fc6d;
  --color-overlay-transparent: rgba(35, 33, 32, 0.3);
  --color-text-shadow-black: rgba(33, 33, 38, 0.8);
  --color-text-shadow-white: rgba(255, 255, 255, 0.8);
  --color-white: #ffffff;
  --color-yellow: rgba(242, 201, 76, 1);
}

// Font families
:root {
  --font-family-regular: var(--font-franklin-gothic);
  --font-family: var(--font-franklin-gothic);
}

// Font sizes
:root {
  --font-size-body12: 12px;
  --font-size-body14: 14px;
  --font-size-body16: 16px;
  --font-size-body20: 20px;
  --font-size-header1: 60px;
  --font-size-header1-mobile: 40px;
  --font-size-header2: 30px;
  --font-size-header2-medium: 32px;
  --font-size-header3: 20px;
  --font-size-header4: 20px;
  --font-size-label: 14px;
}

// Font weights
:root {
  --font-weight-bold: 900;
  --font-weight-regular: 300;
  --font-weight-medium: 500;
}

// Line heights
:root {
  --line-height-body12: 18px;
  --line-height-body14: 22px;
  --line-height-body16: 24px;
  --line-height-header1: 70px;
  --line-height-header1-mobile: 51px;
  --line-height-header2: 38px;
  --line-height-header2-medium: 37px;
  --line-height-header3: 26px;
  --line-height-header4: 26px;
  --line-height-label: 14px;
}

// Spacing
:root {
  --spacing-xl: 12rem;
  --spacing-large: 6rem;
  --spacing-medium: 3rem;
  --spacing-menu-height: 3.5rem;
  --spacing-regular: 1.5rem;
  --spacing-small: 1rem;
  --spacing-xregular: 2rem;
  --spacing-xsmall: 0.5rem;
  --spacing-xxsmall: 0.25rem;
  --spacing-xl-negative: -12rem;
  --spacing-large-negative: -6rem;
  --spacing-medium-negative: -3rem;
  --spacing-menu-height-negative: -3.5rem;
  --spacing-regular-negative: -1.5rem;
  --spacing-small-negative: -1rem;
  --spacing-xregular-negative: -2rem;
  --spacing-xsmall-negative: -0.5rem;
  --spacing-xxsmall-negative: -0.25rem;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}
html {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  min-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: var(--font-weight-regular);
}

#root {
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.no-js img.lazyload {
  display: none;
}

.map {
  position: relative;
  width: 100%;
}
h1,
h2,
h3,
h4,
h6,
p,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  font-family: inherit;
  appearance: none;
  color: inherit;
}
button {
  font-family: inherit;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
}
